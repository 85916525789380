var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('h1',[_vm._v("チーム"+_vm._s(_vm.contents[_vm.contentsType].title))]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h2',[_vm._v("チームID")]),_c('ValidationProvider',{staticClass:"l-mt1 l-w5",attrs:{"name":"チームID","rules":"required","tag":"div","immediate":_vm.$_getValidatedImmediate()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.team_id),expression:"input.team_id"}],attrs:{"type":"text","maxlength":"10","disabled":_vm.contents[_vm.contentsType].flagDisabled},domProps:{"value":(_vm.input.team_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.input, "team_id", $event.target.value)}}}),_c('p',{staticClass:"errorMessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.contents[_vm.contentsType].flagShow)?[_c('h2',[_vm._v("パスワード")]),_c('div',{staticClass:"l-mt1 l-w5"},[_c('input',{attrs:{"type":"text","disabled":_vm.contents[_vm.contentsType].flagDisabled},domProps:{"value":_vm.input.team_password}})])]:_vm._e(),_c('h2',[_vm._v("チーム名")]),_c('ValidationProvider',{staticClass:"l-mt1 l-w5",attrs:{"name":"チーム名","rules":"required","tag":"div","immediate":_vm.$_getValidatedImmediate()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.team_name),expression:"input.team_name"}],attrs:{"type":"text","maxlength":"20"},domProps:{"value":(_vm.input.team_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.input, "team_name", $event.target.value)}}}),_c('p',{staticClass:"errorMessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('h2',[_vm._v("メールアドレス")]),_c('ValidationProvider',{staticClass:"l-mt1 l-w5",attrs:{"name":"メールアドレス","rules":"required|email","tag":"div","immediate":_vm.$_getValidatedImmediate()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input.mail_address),expression:"input.mail_address"}],attrs:{"type":"text","maxlength":"50"},domProps:{"value":(_vm.input.mail_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.input, "mail_address", $event.target.value)}}}),_c('p',{staticClass:"errorMessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"l-mt6 l-2-00-m--all"},[_c('div',[_c('button',{class:(_vm.classButtonStyle + "cancel"),attrs:{"type":"button"},on:{"click":function($event){return _vm.$_goBack()}}},[_vm._v("戻る")]),_c('button',{class:_vm.X_validatedButtonStyle,attrs:{"disabled":_vm.$_setValidatedDisabled(invalid),"type":"button"},on:{"click":function($event){return _vm.$_modalOpen(_vm.contents[_vm.contentsType].modal)}}},[_vm._v("次へ")])]),(_vm.contents[_vm.contentsType].flagShow)?_c('div',[_c('button',{staticClass:"l-mr5 button--text-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.$_modalOpen('changePassWord')}}},[_vm._v("パスワードを変更する")]),_c('button',{class:(_vm.classButtonStyle + "delete"),attrs:{"type":"button"},on:{"click":function($event){return _vm.$_modalOpen('teamDelete')}}},[_vm._v("削除する")])]):_vm._e()])]}}])}),_c('Modal',{attrs:{"modal":_vm.modal},on:{"action":function($event){return _vm.modalAction($event)},"close":function($event){return _vm.$_modalClose()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }