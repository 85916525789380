<template>
    <div class="dashboard">
        <h1>チーム{{ contents[contentsType].title }}</h1>
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
            <h2>チームID</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt1 l-w5" name="チームID" rules="required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="input.team_id" type="text" maxlength="10" :disabled="contents[contentsType].flagDisabled">
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <template v-if="contents[contentsType].flagShow">
                <h2>パスワード</h2>
                <div class="l-mt1 l-w5">
                    <input type="text" :value="input.team_password" :disabled="contents[contentsType].flagDisabled">
                </div>
            </template>
            <h2>チーム名</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt1 l-w5" name="チーム名" rules="required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="input.team_name" type="text" maxlength="20">
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>メールアドレス</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt1 l-w5" name="メールアドレス" rules="required|email" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="input.mail_address" type="text" maxlength="50">
                <p class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <div class="l-mt6 l-2-00-m--all">
                <div>
                    <button :class="`${classButtonStyle}cancel`" type="button" @click="$_goBack()">戻る</button>
                    <button :class="X_validatedButtonStyle" :disabled="$_setValidatedDisabled(invalid)" type="button" @click="$_modalOpen(contents[contentsType].modal)">次へ</button>
                </div>
                <div v-if="contents[contentsType].flagShow">
                    <button class="l-mr5 button--text-link" type="button" @click="$_modalOpen('changePassWord')">パスワードを変更する</button>
                    <button :class="`${classButtonStyle}delete`" type="button" @click="$_modalOpen('teamDelete')">削除する</button>
                </div>
            </div>
        </ValidationObserver>
        <Modal :modal="modal" @action="modalAction($event)" @close="$_modalClose()" />
    </div>
</template>

<script>
    "use strict";

    import common from "@/mixins/common";

    const contents = {
        "create": {
            "flagDisabled": false,
            "flagShow": false,
            "modal": "teamCreate",
            "title": "登録",
        },
        "update": {
            "flagDisabled": true,
            "flagShow": true,
            "modal": "teamUpdate",
            "title": "編集",
        },
    };

    export default {
        "mixins": [
            common,
        ],
        "props": {
            "id": {
                "default": "",
                "required": false,
                "type": String,
            },
        },
        data() {
            return {
                "classButtonStyle": "button--oval--",
                "contents": contents,
                "contentsType": "create",
                "input": {
                    "team_id": "",
                    "team_name": "",
                    "mail_address": "",
                },
            };
        },
        created() {
            if (this.id) {
                // 作成画面から編集画面へ切り替え
                this.contentsType = "update";
                this.fetchingDataTeam();
            } else {
                this.$_setValidatedImmediate();
            }
        },
        "methods": {
            modalAction(key) {
                if (key === "teamCreate") {
                    this.handleCreate();
                } else if (key === "teamUpdate") {
                    this.handleUpdate();
                } else if (key === "teamDelete") {
                    this.handleDelete();
                } else if (key === "changePassWord") {
                    this.handleChangePassWord();
                } else if (key === "teamCreate--finished") {
                    this.$router.push({ "name": "teams_list" });
                } else if (key === "teamUpdate--finished") {
                    this.$router.push({ "name": "teams_list" });
                } else if (key === "teamDelete--finished") {
                    this.$router.push({ "name": "teams_list" });
                } else if (key === "changePassWord--finished") {
                    this.$router.push({ "name": "teams_list" });
                }
            },
            fetchingDataTeam() {
                // 個別チームデータ取得
                common.$_axios.get(
                    `${common.$_configs.apiUrl}/teams/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    this.input = response.data;
                    this.$_setValidatedImmediate();
                }).catch(() => {
                    this.$_modalError("teamFetchError");
                });
            },
            handleCreate() {
                // 個別チームデータ作成
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/teams`,
                    this.input,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleUpdate() {
                // 個別チームデータ更新
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/teams/${this.id}`,
                    this.input,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleDelete() {
                // 個別チームデータ削除
                common.$_axios.delete(
                    `${common.$_configs.apiUrl}/teams/${this.id}`,
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
            handleChangePassWord() {
                // 個別チームパスワード変更
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/teams/password_update/${this.id}`,
                    {},
                    this.$_requestConfig()
                ).then(response => {
                    if (response.data.status_code === 200) {
                        this.$_modalFinished();
                    } else {
                        this.$_modalError();
                    }
                }).catch(() => {
                    this.$_modalError();
                });
            },
        },
    };
</script>
